
export function b2humanSize(num) {
    if (num > 1024 * 1024 * 1024) {
        return (num / 1024 / 1024 / 1024).toFixed(1) + "G"
    } else if (num > 1024 * 1024) {
        return (num / 1024 / 1024).toFixed(1) + "M"
    } else {
        return (num / 1024).toFixed(1) + "K"
    }
}

export function isImage(fileType) {
    return (fileType === "image/png" ||
        fileType === "image/jpeg" ||
        fileType === "image/gif")
}