<template>
    <div class="login-background">
        <div class="login-container">
            <div class="title-container">
                <h3 class="title">注册</h3>
            </div>
            <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on"
                label-position="left">

                <el-form-item prop="username">

                    <el-input v-model="loginForm.username" placeholder="请输入用户名" name="username" type="text"
                        auto-complete="on" @keyup.enter.native="focusNext('phone')">
                        <el-button slot="prepend" icon="el-icon-user"></el-button>
                    </el-input>
                </el-form-item>

                <el-form-item prop="phone">

                    <el-input v-model="loginForm.phone" placeholder="请输入手机号" name="phone" type="text" auto-complete="on"
                        @keyup.enter.native="focusNext('email')" ref="phone">
                        <el-button slot="prepend" icon="el-icon-phone"></el-button>
                    </el-input>
                </el-form-item>


                <el-form-item prop="email">

                    <el-input v-model="loginForm.email" placeholder="请输入邮箱" name="email" type="text" auto-complete="on"
                        @keyup.enter.native="focusNext('password')" ref="email">
                        <el-button slot="prepend" icon="el-icon-message"></el-button>
                    </el-input>
                </el-form-item>

                <el-form-item prop="password">
                    <el-input v-model.trim="loginForm.password" placeholder="请输入密码" :type="passw" clearable
                        @blur="onBlur" @keyup.enter.native="handleLogin" ref="password">
                        <el-button slot="prepend" icon="el-icon-lock"></el-button>

                        <!-- input中加图标必须要有slot="suffix"属性，不然无法显示图标 -->
                        <i slot="suffix" :class="icon" @click="showPass"></i>
                    </el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input v-model.trim="loginForm.repeatPassword" placeholder="请再次输入密码" :type="passw" clearable
                        @blur="onBlur" @keyup.enter.native="handleLogin" ref="password">
                        <el-button slot="prepend" icon="el-icon-lock"></el-button>

                        <!-- input中加图标必须要有slot="suffix"属性，不然无法显示图标 -->
                        <i slot="suffix" :class="icon" @click="showPass"></i>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" style="width:100%;" @click="handleReg">注册
                    </el-button>
                </el-form-item>

            </el-form>
            <div class="tips">
                <span style="color:#409EFF;cursor:pointer;" @click="login">返回登录</span>
            </div>
        </div>
    </div>
</template>
 
<script>
// 引入去除空格工具
import { validUsername } from '@/utils/validate'
import { mapMutations } from 'vuex'
import { Registry } from '../../axios/axios'
export default {
    data() {
        const validateUsername = (rule, value, callback) => {
            if (!validUsername(value)) {
                callback(new Error('用户名不能为空！'))
            } else {
                callback()
            }
        }
        const validatePassword = (rule, value, callback) => {
            if (value.length < 6) {
                callback(new Error('密码最少为6位字符！'))
            } else {
                callback()
            }
        }
        return {
            // 头像状态
            TxStatus: true,
            loginForm: {
                username: '',
                password: '',
                repeatPassword: '',
            },
            // 登录规则
            loginRules: {
                username: [{ required: true, trigger: 'blur', validator: validateUsername }],
                password: [{ required: true, trigger: 'blur', validator: validatePassword }]
            },
            redirect: undefined,
            icon: "el-input__icon el-icon-view",
            passw: "password",
        }
    },
    watch: {
        $route: {
            handler: function (route) {
                this.redirect = route.query && route.query.redirect
            },
            immediate: true
        }
    },
    methods: {
        ...mapMutations([]),
        //密码的隐藏和显示
        showPass() {
            //点击图标是密码隐藏或显示
            if (this.passw == "text") {
                this.passw = "password";
                //更换图标
                this.icon = "el-input__icon el-icon-view";
            } else {
                this.passw = "text";
                this.icon = "el-input__icon el-icon-loading";
                setTimeout(() => {
                    this.icon = "";
                }, 500)
            }
        },
        //密码失焦事件
        onBlur() {
            this.passw = "password";
            this.icon = "el-input__icon el-icon-view";
        },

        // 登录业务
        handleReg() {
            this.$refs.loginForm.validate(() => {
                // 如果符合验证规则
                Registry(this.loginForm)
                    .then((res) => {
                        console.log(res.data)
                        this.$message.success("注册成功,请登录")
                        this.$router.push({ path: "/login" })
                    })
                    .catch((err) => {
                        console.log(err)
                        this.$message.error(err.response.data.info)
                    })
            })
        },
        focusNext(nextRef) {
            this.$refs[nextRef].focus()
        },
        login() {
            this.$router.push('/login')
        }
    }
}
</script>
 
 
<style  scoped>
.login-background {
    /* background-color: gray; */
    width: 100%;
    height: 100%;
    /* background-color: #889aa4; */
    background-image: url(../../assets/img/login-bg2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.login-container {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 350px;
    height: 520px;
    margin: -260px -175px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.3);
    overflow: hidden;
}

.login-form {
    /* padding: 30px; */
    /* margin: 10px; */
}

.el-form-item {
    padding: 10px;
    margin: 10px;
}

.tips {
    font-size: 18px;
    text-align: center;
    color: #000;
    margin-bottom: 10px;
}

.title-container {
    position: relative;
}

.title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    /* color: #fff; */
    border-bottom: 1px solid #ddd;
}

.img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
</style>