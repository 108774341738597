
<template>
    <div class="login-background">
        <div class="login-container">
            <div class="title-container">
                <h3 class="title" >登录</h3>
            </div>
            <Login ></Login>
            <div class="tips" v-if="isLogin">
                <span style="margin-right:20px;">如果您还没有账号请先 <span style="color:#409EFF;cursor:pointer;"
                        @click="register">注册</span></span>
            </div>

        </div>
    </div>
</template>
 
<script>
// 引入去除空格工具
import Login from '../components/pages/Login.vue'
export default {
    components: { Login },
    name: 'LoginRegister',
    data() {

        return {
            isLogin: true

        }
    },
    watch: {
        $route: {
            handler: function (route) {
                this.redirect = route.query && route.query.redirect
            },
            immediate: true
        }
    },
    methods: {

        // 注册业务
        register() {
            this.$router.push('/register')
            // this.$message.info("请联系管理员")
        },
        login() {
            this.isLogin = true
        }
    }
}
</script>
 
 
<style scoped>
.login-background {
    /* background-color: gray; */
    width: 100%;
    height: 100%;
    /* background-color: #889aa4; */
    background-image: url(../assets/img/login-bg2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.login-container {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 350px;
    height: 380px;
    margin: -190px -175px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.3);
    overflow: hidden;
}

.tips {
    font-size: 18px;
    text-align: center;
    color: #000;
    margin-bottom: 10px;
}

.title-container {
    position: relative;
}

.title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    /* color: #fff; */
    border-bottom: 1px solid #ddd;
}


.img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
</style>