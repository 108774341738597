import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: "" || localStorage.getItem("token"),
    username: "" || localStorage.getItem("username"),
    userid: "" || localStorage.getItem("userid"),
    usertype: "" || localStorage.getItem("usertype"),
    taskId: "" || localStorage.getItem("taskId"),
    menuCollapse: false
  },
  mutations: {
    storeUserInfo(state, info) {
      state.token = info.token
      state.userid = info.userid
      state.username = info.username
      state.usertype = info.usertype
      localStorage.setItem("token", info.token)
      localStorage.setItem("username", info.username)
      localStorage.setItem("userid", info.userid)
      localStorage.setItem("usertype", info.usertype)
    },
    Logout(state) {
      state.token = ""
      state.username = ""
      state.userid = ""
      state.usertype = ""
      localStorage.setItem("token", "")
      localStorage.setItem("username", "")
      localStorage.setItem("userid", "")
      localStorage.setItem("usertype", "")
    },
    collapseMenu(state, val) {
      state.menuCollapse = val
    },
    setTaskId(state, taskId) {
      state.taskId = taskId
    },
    cleanTaskId(state) {
      state.taskId = ""
    }
  },
  actions: {},
  modules: {}
})