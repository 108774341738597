<template>
  <!-- <div id="app"> -->
  <div id="app">
    <router-view></router-view>
  </div>

</template>
<script>


export default {
  data() {
    return {
    };
  },
  computed: {

  },

}
</script>
<style>
#app{
  height: 100%;
  margin: 0px;
  padding: 0px;
}
</style>
