
<template>
    <div>

        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on"
            label-position="left">
            <!-- 头像区域 -->
            <!-- <div v-if="TxStatus" class="avatar-box">
                <img src="../assets/avator.jpg" alt="">
            </div> -->

            <el-form-item prop="username">
                <el-input v-model="loginForm.username" placeholder="请输入用户名" name="username" type="text"
                    auto-complete="on" @keyup.enter.native="focusNext('password')">
                    <el-button slot="prepend" icon="el-icon-user"></el-button>
                </el-input>
            </el-form-item>

            <el-form-item prop="password">
                <el-input v-model.trim="loginForm.password" placeholder="请输入密码" :type="passw" clearable @blur="onBlur"
                    @keyup.enter.native="handleLogin" ref="password">
                    <el-button slot="prepend" icon="el-icon-lock"></el-button>

                    <!-- input中加图标必须要有slot="suffix"属性，不然无法显示图标 -->
                    <i slot="suffix" :class="icon" @click="showPass"></i>
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" style="width:100%;" @click="handleLogin">登录
                </el-button>
            </el-form-item>

        </el-form>
    </div>
</template>
 
<script>
// 引入去除空格工具
import { validUsername } from '@/utils/validate'
import { mapMutations } from 'vuex'
import { createBucket } from '../../minio/minio'
import { Login, GetUser } from '../../axios/axios'
export default {
    data() {
        const validateUsername = (rule, value, callback) => {
            if (!validUsername(value)) {
                callback(new Error('用户名不能为空！'))
            } else {
                callback()
            }
        }
        const validatePassword = (rule, value, callback) => {
            if (value.length < 6) {
                callback(new Error('密码最少为6位字符！'))
            } else {
                callback()
            }
        }
        return {
            // 头像状态
            TxStatus: true,
            loginForm: {
                username: '',
                password: ''
            },
            // 登录规则
            loginRules: {
                username: [{ required: true, trigger: 'blur', validator: validateUsername }],
                password: [{ required: true, trigger: 'blur', validator: validatePassword }]
            },
            redirect: undefined,
            icon: "el-input__icon el-icon-view",
            passw: "password",
        }
    },
    watch: {
        $route: {
            handler: function (route) {
                this.redirect = route.query && route.query.redirect
            },
            immediate: true
        }
    },
    methods: {
        ...mapMutations(['storeUserInfo']),
        //密码的隐藏和显示
        showPass() {
            //点击图标是密码隐藏或显示
            if (this.passw == "text") {
                this.passw = "password";
                //更换图标
                this.icon = "el-input__icon el-icon-view";
            } else {
                this.passw = "text";
                this.icon = "el-input__icon el-icon-loading";
                setTimeout(() => {
                    this.icon = "";
                }, 500)
            }
        },
        //密码失焦事件
        onBlur() {
            this.passw = "password";
            this.icon = "el-input__icon el-icon-view";
        },

        // 登录业务
        handleLogin() {
            this.$refs.loginForm.validate((valid) => {
                // 如果符合验证规则
                if (valid) {
                    const loading = this.$loading({
                        lock: true,
                        text: '登录中...',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    // this.loading = true

                    Login(this.loginForm.username, this.loginForm.password).then((res) => {
                        GetUser(res.data.data).then(
                            resUserInfo => {
                                console.log(resUserInfo.data.data)
                                this.storeUserInfo({ "token": res.data.data,
                                 "username": this.loginForm.username,
                                  "userid": resUserInfo.data.data.user_id ,
                                  "usertype":resUserInfo.data.data.user_type})
                                createBucket(resUserInfo.data.data.user_id)
                                console.log(res.data.data)
                                loading.close()
                                this.$router.push({ path: this.redirect || '/summary' })
                            }
                        )

                    }).catch((err) => {
                        console.log(err)
                        this.$message.error(err.response.data.info)
                        loading.close()
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        // 注册业务
        register() {
            console.log('123')
            this.$router.push({ name: 'Register' })
        },
        focusNext(nextRef) {
            this.$refs[nextRef].focus()
        }
    }
}
</script>
 
 
<style  scoped>

.login-title {
    text-align: center;
}

.login-form {
    /* padding: 30px; */
    /* margin: 10px; */
}

.el-form-item {
    padding: 10px;
    margin: 10px;
}

.tips {
    font-size: 18px;
    text-align: center;
    color: #000;
    margin-bottom: 10px;
}

.title-container {
    position: relative;


}

.title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    /* color: #fff; */
    border-bottom: 1px solid #ddd;
}



.img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
</style>