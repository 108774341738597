const axios = require('axios');
import {
    iamUrl,
    dataServerUrl
} from '../env';
import store from '../store';
export function Login(username, password) {
    return axios.post(iamUrl + '/api/v1/actions/login', {
        username: username,
        password: password
    })
}

export function Registry(form) {
    return axios.post(iamUrl + '/api/v1/actions/signup', {
        username: form.username,
        password: form.password,
        email: form.email,
        phone_number: form.phone
    })
}
export function GetUser(token) {
    return axios.get(iamUrl + '/api/v1/actions/describeMe', {
        headers: {
            "Authorization": "Bearer " + token
        }
    })
}
export function GetDatasets(page, page_size) {
    console.log(page_size, page)

    return axios.get(dataServerUrl + '/api/v1/datasets', {
        headers: {
            "Authorization": "Bearer " + store.state.token
        },
        params: {
            page_size: page_size,
            page_num: page
        }
    })
}
export function DelDataset(set_id) {
    return axios.delete(dataServerUrl + '/api/v1/datasets/' + set_id, {
        headers: {
            "Authorization": "Bearer " + store.state.token
        }
    })
}

export function GetDataset(set_id) {
    return axios.get(dataServerUrl + '/api/v1/datasets/' + set_id, {
        headers: {
            "Authorization": "Bearer " + store.state.token
        }
    })
}
export function CreateDataset(name, crop_id, device_id, desc) {
    return axios.post(dataServerUrl + '/api/v1/datasets', {
        name: name,
        crop_code: crop_id,
        device_code: device_id,
        description: desc,
    }, {
        headers: {
            "Authorization": "Bearer " + store.state.token
        }
    })
}

export function CreateDatasetPreview(set_id) {
    return axios.delete(dataServerUrl + '/api/v1/datasets/' + set_id+'/createPreview', {
        headers: {
            "Authorization": "Bearer " + store.state.token
        }
    })
}
export function GetCrops() {
    return axios.get(dataServerUrl + '/api/v1/crops', {
        headers: {
            "Authorization": "Bearer " + store.state.token
        }
    })
}

export function GetTasks(page, page_size) {
    console.log(page, page_size)
    return axios.get(dataServerUrl + '/api/v1/tasks', {
        headers: {
            "Authorization": "Bearer " + store.state.token
        },
        params: {
            page_size: page_size,
            page_num: page
        }
    })
}

export function GetTaskById(task_id) {
    console.log(task_id)

    return axios.get(dataServerUrl + '/api/v1/tasks/' + task_id, {
        headers: {
            "Authorization": "Bearer " + store.state.token
        }
    })
}

export function DelTask(task_id) {
    return axios.delete(dataServerUrl + '/api/v1/tasks/' + task_id, {
        headers: {
            "Authorization": "Bearer " + store.state.token
        }
    })
}

export function GetModels() {
    return axios.get(dataServerUrl + '/app/model', {
        headers: {
            "Authorization": "Bearer " + store.state.token
        }
    })
}

export function GetDevices() {
    return axios.get(dataServerUrl + '/api/v1/devices', {
        headers: {
            "Authorization": "Bearer " + store.state.token
        }
    })
}
export function CreateTask(name, task_class_id, device_id, crop_id, set_id_list, desc) {
    console.log(name, task_class_id, set_id_list, desc)
    return axios.post(dataServerUrl + '/api/v1/tasks', {
        name: name,
        task_type_code: task_class_id,

        datasets: set_id_list,
        desc: desc
    }, {
        headers: {
            "Authorization": "Bearer " + store.state.token
        }
    })
}

export function RunTask(task_id) {
    return axios.put(dataServerUrl + '/api/v1/tasks/' + task_id + '/run', {}, {
        headers: {
            "Authorization": "Bearer " + store.state.token
        }
    })
}

export function TerminateTask(task_id) {
    return axios.put(dataServerUrl + '/api/v1/tasks/' + task_id + '/stop', {}, {
        headers: {
            "Authorization": "Bearer " + store.state.token
        }
    })
}

export function GetTaskClass() {
    return axios.get(dataServerUrl + '/api/v1/tasktypes', {
        headers: {
            "Authorization": "Bearer " + store.state.token
        }
    })
}


export function GetCount(coordinates) {
    console.log("xxxx", store.state.token, store.state.taskId, coordinates, "yyy")
    return axios.post(dataServerUrl + '/api/v1/tasks/' + store.state.taskId + '/countPolygon', {
        points: coordinates
    }, {
        headers: {
            "Authorization": "Bearer " + store.state.token
        },
        params: {
            page_size: 1,
            page_num: 1
        }
    })
}