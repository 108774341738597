const Minio = require('minio')
import {
    minioServer,
    minioAccessKey,
    minioSecretKey
} from '../env';
const minioClient = new Minio.Client({
    endPoint: minioServer, // 地址
    // port: 80, // 端口号，若地址为类似test.minio.com,就不必写端口号
    useSSL: true, // 是否使用ssl
    accessKey: minioAccessKey, // 登录的accessKey
    secretKey: minioSecretKey // secretKey
});

export function createBucket(bucket) {
    return minioClient.bucketExists(bucket.toLowerCase(), function (err, exist) {
        if (err) {
            console.log(err)
            return
        }
        if (exist) {
            console.log("bucket exist.", bucket.toLowerCase())
            return
        } else {
            minioClient.makeBucket(bucket.toLowerCase(), function (err) {
                if (err) return console.log('Error creating bucket.', err)
                console.log('Bucket created successfully in "us-east-1".')
            })
        }

    })
}
// 两个参数，存储桶的名字，要上传文件的名字。例如test.txt
/**
 *
 * @export 获取上传链接（url方法）获取上传的url;会生成一个带预签名的链接
 * @param {*} bucket String 存储桶的名字
 * @param {*} totalFolderName String 总文件夹名字 例如:imagelibrary
 * @param {*} fileName String 文件名字
 * @return {*} Promise
 */
export function getUploadUrl(bucket, totalFolderName, fileName) {
    // let defaultPath = getNowTime("fileDate"); // 添加默认的以日为分类的文件夹
    return minioClient.presignedPutObject(bucket.toLowerCase(), `${totalFolderName}/${fileName}`)
}

// 通过url上传
/**
 *
 * @export 上传文件（url方法）通过获取带预签名的链接上传
 * @param {*} url String 预签名链接
 * @param {*} data Object 文件对象
 * @return {*} Promise
 */
export function uploadByUrl(url, data) {
    return fetch(url, {
        mode: "cors", // 解决跨域
        headers: {
            Accept: "application/json,text/plain,/",
        },
        method: "PUT",
        body: data,
    });
}

export function getObjects(bucket, totalFolderName) {
    const objectsList = new Promise((resolve, reject) => {
        const objectsListTemp = [];
        const stream = minioClient.listObjectsV2(bucket.toLowerCase(), totalFolderName, true, '')

        stream.on('data', obj => objectsListTemp.push(obj));
        stream.on('error', reject);
        stream.on('end', () => {
            resolve(objectsListTemp);
        });
    });
    console.log(objectsList, 1111)
    return objectsList
}

export function getObjectsWithMetadata(bucket, totalFolderName) {
    return new Promise((resolve, reject) => {
        const objectsListTemp = [];
        const stream = minioClient.extensions.listObjectsV2WithMetadata(bucket.toLowerCase(), totalFolderName, true, '')

        stream.on('data', obj => objectsListTemp.push(obj));
        stream.on('error', reject);
        stream.on('end', () => {
            resolve(objectsListTemp);
        });
    });
}

export function getPresignedGetObject(bucket, objectname) {
    return minioClient.presignedGetObject(bucket.toLowerCase(), objectname, 86400)
}

export function deleteObject(bucket, objectname) {
    minioClient.removeObject(bucket.toLowerCase(), objectname, function (err) {
        if (err) {
            return console.log('Unable to remove object', err)
        }
        console.log('Removed the object')
    })
}

export function deleteAllObjects(bucket, totalFolderName) {
    var objectsList = []

    // List all object paths in bucket my-bucketname.
    var objectsStream = minioClient.listObjects(bucket.toLowerCase(), totalFolderName, true)

    objectsStream.on('data', function (obj) {
        objectsList.push(obj.name);
    })

    objectsStream.on('error', function (e) {
        console.log(e);
    })

    objectsStream.on('end', function () {

        minioClient.removeObjects(bucket.toLowerCase(), objectsList, function (e) {
            if (e) {
                return console.log('Unable to remove Objects ', e)
            }
            console.log('Removed the objects successfully')
        })

    })
}