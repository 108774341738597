import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginRegister from '../views/LoginRegister.vue'
import Register from '../components/pages/Register.vue'
Vue.use(VueRouter)

const routes = [{
    path: "/login",
    name: "LoginRegister",
    component: LoginRegister
  },{
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: '/data/set/:id',
    name: 'Data',
    component: () => import( /* webpackChunkName: "about" */ '../components/pages/DatasetDetail.vue'),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },

  },
  {
    path: '/result/grid',
    name: 'Global',
    component: () => import( /* webpackChunkName: "about" */ '../components/pages/Grid.vue'),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },

  },
  {
    path: '/result/taskresult',
    name: 'Result',
    component: () => import( /* webpackChunkName: "about" */ '../components/pages/TaskResult.vue'),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },

  },
  {
    path: '/map/geojson',
    name: 'Result',
    component: () => import( /* webpackChunkName: "about" */ '../components/pages/Geojson.vue'),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },

  },
  {
    path: '/',
    redirect: '/summary'
  },
  {
    path: '/',
    name: 'Home',
    component: () => import( /* webpackChunkName: "about" */ '../views/Home.vue'),
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
    children: [{
        path: '/summary',
        name: 'Summary',
        component: () => import( /* webpackChunkName: "about" */ '../components/pages/Summary.vue'),
        meta: {
          requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
        },

      },
      {
        path: '/data/set',
        name: 'Data',
        component: () => import( /* webpackChunkName: "about" */ '../components/pages/Data.vue'),
        meta: {
          requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
        },

      },
      {
        path: '/userinfo',
        name: 'User',
        component: () => import( /* webpackChunkName: "about" */ '../components/pages/Userinfo.vue'),
        meta: {
          requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
        },

      },
      {
        path: '/password',
        name: 'Password',
        component: () => import( /* webpackChunkName: "about" */ '../components/pages/Password.vue'),
        meta: {
          requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
        },

      },
      // {
      //   path: '/data/set/:id',
      //   name: 'DatasetDetail',
      //   component: () => import(/* webpackChunkName: "about" */ '../components/DatasetDetail.vue'),
      //   meta: {
      //     requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
      //   },

      // },
      {
        path: '/task',
        name: 'Task',
        component: () => import( /* webpackChunkName: "about" */ '../components/pages/Task.vue'),
        meta: {
          requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
        },

      },

      {
        path: '/result/pic/task',
        name: 'Picture',
        component: () => import( /* webpackChunkName: "about" */ '../components/pages/Picture.vue'),
        meta: {
          requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
        },

      }
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router