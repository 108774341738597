Date.prototype.Format = function (fmt) { //author: meizz
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

/**
 * @desc 时间工具对象
 */
export const DateUtils = {
 
    format1: 'yyyy-MM-dd HH:mm:ss',
 
    format2: 'yyyy年MM月dd日 HH时mm分ss秒',
 
    /**
     * 方法描述：将字符串转为时间对象
     * @param time {String} 时间字符串
     * @param format {String} 时间格式
     * @returns {Date}
     */
    convert(time, format) {
        if (time instanceof Date) {
            return time;
        }
        if (time && typeof time === 'string') {
            let getValue = function (regex) {
                let str = regex ? time.substr(regex.index, regex[0].length) : '';
                return str ? parseInt(str) : undefined;
            }
            // 各个时间的值，未获取到则取最小值
            let times = [
                getValue(/y+/.exec(format)) || 0, // 年
                getValue(/M+/.exec(format)) || 0, // 月
                getValue(/d+/.exec(format)) || 1, // 日
                /H+/.test(format) ? getValue(/H+/.exec(format)) : ((/h+/.test(format) && /pm/i.test(format)) ? getValue(/h+/.exec(format)) + 12 : getValue(/h+/.exec(format))) || 0, // 时
                getValue(/m+/.exec(format)) || 0, // 分
                getValue(/s+/.exec(format)) || 0, // 秒
                function () {
                    let reg, regArr;
                    reg = new RegExp(/(\d{1,3}).*/, 'g'); reg.lastIndex = format.indexOf("S");
                    return reg.lastIndex > -1 ? (regArr = reg.exec(time)) && parseInt(regArr[1]) : undefined;
                } () || 0// 毫秒
            ];
            times[1] && (times[1] -= 1);
            return new Date(...times);
        }
    }
}